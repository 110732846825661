//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportMixin from "@/mixins/Report";
import RangeCalendar from "@/components/RangeCalendar.vue";
import ModalListmImport from "@/components/modals/ModalListmImport.vue";
import ModalListMRemoveItem from "@/components/modals/ModalListMRemoveItem.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
import MainTitle from "@/components/MainTitle.vue";
import AppTabs from "@/components/AppTabs.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import ProductsTable from "@/components/ListM/ProductsTable.vue";
import ProductsNewNameDetailTable from "@/components/ListM/ProductsNewNameDetailTable.vue";
import BrandsTable from "@/components/ListM/BrandsTable.vue";
import BrandsNewProductsDetailTable from "@/components/ListM/BrandsNewProductsDetailTable.vue";
import BrandsNewSellersDetailTable from "@/components/ListM/BrandsNewSellersDetailTable.vue";
import SellersTable from "@/components/ListM/SellersTable.vue";
import SellersNewProductsDetailTable from "@/components/ListM/SellersNewProductsDetailTable.vue";
import SellersNewBrandsDetailTable from "@/components/ListM/SellersNewBrandsDetailTable.vue";
import CategoriesTable from "@/components/ListM/CategoriesTable.vue";
import CategoriesNewProductsDetailTable from "@/components/ListM/CategoriesNewProductsDetailTable.vue";
import CategoriesNewBrandsDetailTable from "@/components/ListM/CategoriesNewBrandsDetailTable.vue";
import CategoriesNewSellersDetailTable from "@/components/ListM/CategoriesNewSellersDetailTable.vue";
const tableComponents = {
  productPrice: ProductsTable,
  productReview: ProductsTable,
  productDiscount: ProductsTable,
  productMaxQuantity: ProductsTable,
  productPhotoCount: ProductsTable,
  productCategory: ProductsTable,
  productKeyword: ProductsTable,
  productNewName: ProductsTable,
  productNewNameDetail: ProductsNewNameDetailTable,
  brandNewProduct: BrandsTable,
  brandNewProductDetail: BrandsNewProductsDetailTable,
  brandNewSeller: BrandsTable,
  brandNewSellerDetail: BrandsNewSellersDetailTable,
  sellerNewProduct: SellersTable,
  sellerNewProductDetail: SellersNewProductsDetailTable,
  sellerNewBrand: SellersTable,
  sellerNewBrandDetail: SellersNewBrandsDetailTable,
  categoryNewProduct: CategoriesTable,
  categoryNewProductDetail: CategoriesNewProductsDetailTable,
  categoryNewBrand: CategoriesTable,
  categoryNewBrandDetail: CategoriesNewBrandsDetailTable,
  categoryNewSeller: CategoriesTable,
  categoryNewSellerDetail: CategoriesNewSellersDetailTable
};
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$list;
    const name = (_this$list = this.list) === null || _this$list === void 0 ? void 0 : _this$list.title;
    return {
      title: name ? `Список "${name}"` : undefined
    };
  },
  data() {
    return {
      list: null,
      showModalEditLists: false,
      tabs_tables_all: [
      // product
      {
        id: "productPrice",
        title: ""
      }, {
        id: "productReview",
        title: ""
      }, {
        id: "productDiscount",
        title: ""
      }, {
        id: "productMaxQuantity",
        title: ""
      }, {
        id: "productPhotoCount",
        title: ""
      }, {
        id: "productCategory",
        title: ""
      }, {
        id: "productKeyword",
        title: ""
      }, {
        id: "productNewName",
        title: "Календарь изменений" // pNN",
      }, {
        id: "productNewNameDetail",
        title: "Детализация" // pNN",
      },
      // brand
      {
        id: "brandNewProduct",
        title: "Календарь изменений" // bNP",
      }, {
        id: "brandNewProductDetail",
        title: "Детализация" // bNPD",
      }, {
        id: "brandNewSeller",
        title: "Календарь изменений" // bNS",
      }, {
        id: "brandNewSellerDetail",
        title: "Детализация" // bNSD",
      },
      // seller
      {
        id: "sellerNewProduct",
        title: "Календарь изменений" // bNP",
      }, {
        id: "sellerNewProductDetail",
        title: "Детализация" // bNPD",
      }, {
        id: "sellerNewBrand",
        title: "Календарь изменений" // sNB",
      }, {
        id: "sellerNewBrandDetail",
        title: "Детализация" // sNBD",
      },
      // category
      {
        id: "categoryNewProduct",
        title: "Календарь изменений" // cNP",
      }, {
        id: "categoryNewProductDetail",
        title: "Детализация" //cNPD",
      }, {
        id: "categoryNewBrand",
        title: "Календарь изменений" //cNB",
      }, {
        id: "categoryNewBrandDetail",
        title: "Детализация" //cNBD",
      }, {
        id: "categoryNewSeller",
        title: "Календарь изменений" //cNS",
      }, {
        id: "categoryNewSellerDetail",
        title: "Детализация" //cNSD",
      }],

      tabs_tables_model: null
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    // override mixin marketplace getter
    getMP() {
      var _this$list2;
      return (_this$list2 = this.list) === null || _this$list2 === void 0 ? void 0 : _this$list2.mp;
    },
    getTableId() {
      return this._uid;
    },
    async loadList() {
      this.list = await this.$store.dispatch("listM/getListM", {
        id: this.$route.params.id
      });

      //alert('1: this.list='+JSON.stringify(this.list))
    },

    getTableComponent(id) {
      document.getElementById("ListMImportBtn").style.display = ["productPrice", "productReview", "productDiscount", "productMaxQuantity", "productPhotoCount", "productCategory", "productKeyword", "productNewName", "brandNewProduct", "brandNewSeller", "sellerNewProduct", "sellerNewBrand", "categoryNewProduct", "categoryNewSeller", "categoryNewBrand"].includes(id) ? "block" : "none";
      return tableComponents[id];
    },
    deselect() {
      this.table_data.data.forEach(el => {
        el.checked = false;
      });
      this.select_all = false;
    },
    deleteItem() {
      let data = [];
      this.table_data.data.forEach(item => {
        if (!item.checked) {
          data.push(item);
        }
      });
      this.table_data.data = [...data];
    },
    onImportClick() {
      this.$refs.modalListmImport.open({
        item: this.list
      });
    },
    onImportComplete() {
      this.$refs.table.refresh();
    },
    onRemove({
      items,
      clean,
      refresh
    }) {
      this.$refs.modalListMRemoveItem.open({
        list: this.list,
        items,
        onComplete() {
          clean();
          refresh();
        }
      });
    }
  },
  computed: {
    tabs_tables() {
      return this.tabs_tables_all.filter(tab => {
        // alert('4: tab.id='+tab.id)
        // alert('5: this.list='+JSON.stringify(this.list))

        //if( tab.id === this.list.monitor ){
        if (tab.id.indexOf(this.list.monitor) === 0) {
          //alert('6: true')
          return true;
        }

        //alert('7: false')
        return false;

        // if( this.list.entity==='brand'){
        //     return true;
        // }
      });
    },

    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Список ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    selectedItems() {
      if (this.table_data && this.table_data.data) {
        let res = [];
        this.table_data.data.forEach(el => {
          if (el.checked) res.push(el);
        });
        return res;
      } else {
        return [];
      }
    },
    listKey() {
      return `${this.$route.params.id}`;
    },
    reportSettings() {
      //alert('2: this.list='+JSON.stringify(this.list))

      if (!this.calendar || !this.list) {
        return null;
      }

      //alert('3: this.list='+JSON.stringify(this.list))

      return {
        id: this.$route.params.id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs
        //monitor: m,
      };
    },

    routeParams() {
      var _this$list3;
      return {
        mp: (_this$list3 = this.list) === null || _this$list3 === void 0 ? void 0 : _this$list3.mp
      };
    }
  },
  watch: {
    listKey() {
      this.loadList();
    }
  },
  components: {
    RangeCalendar,
    ModalListmImport,
    DataTableActions,
    MainTitle,
    AppTabs,
    ////ProductsMTable,
    ModalListMRemoveItem,
    SwitchInput
  }
};