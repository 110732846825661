//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
//import { getEntityTableColumns } from "@/utils/enums";
import { dateRangeDaysArray, dateFormat } from "@/utils/dates";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("listM/getListMBrands", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Cписок ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    columns() {
      const days = dateRangeDaysArray(this.reportSettings.date, this.reportSettings.date2);
      let position = 2;
      return [{
        title: "Бренд",
        position: 1,
        show: true,
        name: "brand",
        width: 440,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "BrandInfo",
              params: {
                ...this.routeParams,
                b_id: item.b_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.brand,
            target: "_blank"
          };
        }
      },
      ////...getEntityTableColumns("brand", 2),

      /*
              // &: 2022-05-21
              // {
              //     title: "Впервые",
              //     show: true,
              //     name: "date",
              //     width: 100,
              //     filter: "dateRange",
              //     type: "date",
              // },
              // {
              //     title: "Последний раз",
              //     show: true,
              //     name: "date2",
              //     width: 110,
              //     filter: "dateRange",
              //     type: "date",
              // },
              */

      ...days.map(date => {
        return {
          title: dateFormat(date),
          position: ++position,
          show: true,
          name: date,
          width: 100,
          type: "number",
          filter: "numberRange",
          noSettings: true
        };
      })];
    }
  }
};