//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
////import { getEntityTableColumns } from "@/utils/enums";
import { dateRangeDaysArray, dateFormat } from "@/utils/dates";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("listM/getListMProducts", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Cписок ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    columns() {
      const days = dateRangeDaysArray(this.reportSettings.date, this.reportSettings.date2);
      let position = 4;
      return [{
        title: "Товар",
        position: 1,
        show: true,
        name: "title",
        width: 380,
        filter: "text",
        type: "link",
        noOverflow: true,
        getImage: item => item.image,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                ...this.routeParams,
                p_id: item.p_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.title,
            target: "_blank"
          };
        }
      }, {
        title: "SKU",
        position: 2,
        show: true,
        name: "sku",
        width: 100,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            href: item.mp_url,
            text: item.sku
          };
        }
      }, {
        title: "Бренд",
        show: true,
        name: "brand",
        width: 100,
        filter: "select",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "BrandInfo",
              params: {
                b_id: item.b_id,
                mp: this.routeParams.mp
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.brand,
            target: "_blank"
          };
        }
      }, {
        title: "Продавец",
        show: true,
        name: "seller",
        width: 200,
        filter: "select",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "SellerInfo",
              params: {
                s_id: item.s_id,
                mp: this.routeParams.mp
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.seller,
            target: "_blank"
          };
        }
      },
      //...getEntityTableColumns("product", 3),

      /*
      {
          title: "Впервые",
          show: true,
          name: "date",
          width: 100,
          filter: "dateRange",
          type: "date",
      },
      {
          title: "Последний раз",
          show: true,
          name: "date2",
          width: 110,
          filter: "dateRange",
          type: "date",
      },
      */

      ...days.map(date => {
        return {
          title: dateFormat(date),
          position: ++position,
          show: true,
          name: date,
          width: 100,
          type: "number",
          filter: "numberRange",
          noSettings: true
        };
      })];
    }
  }
};